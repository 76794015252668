/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable max-nested-callbacks */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { compact, groupBy, keys, omit, orderBy, round, template, uniqWith } from 'lodash'
import { CircularProgress, Grid } from '@mui/material'
import moment from 'moment'
import { MainButton, RedButton } from '../components/styled/buttons'
import HomeAction from '../../offline/actions/HomeAction'
import AgriAction from '../agri/actions/AgriAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import CmsAction from '../cms/actions/CmsAction'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import { hasValue } from '../../../utils/NumberUtil'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import DtoMatEvent from '../referencials/materiels/dto/DtoMatEvent'
import ModalMatEvent from './ModalMatEvent'
import { getColorAndLabelWaterTurn, getIPSIcon, getIPSLabel, getRestrictionIconV2 } from '../../../utils/AgriUtils'
import { darkRed, mainColor } from '../components/styled/theme'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoManagementUnitRestriction from '../referencials/dto/DtoManagementUnitRestriction'
import DtoAssociatedStation from '../referencials/installations/dto/DtoAssociatedStation'
import DtoMapSituationResult from '../referencials/installations/dto/DtoMapSituationResult'
import DtoMapSituation from '../referencials/installations/dto/DtoMapSituation'
import DtoDroughtSectorRestriction from '../agri/dto/DtoDroughtSectorRestriction'
import { getSettingInt } from '../../../utils/FormUtils'
import { ArrowForwardIos, CancelOutlined, CheckCircleOutlined, Error, Star, StarBorder } from '@mui/icons-material'
import { AGRI, AGRI_RSEAU, SAMPLE_TYPES } from '../agri/constants/AgriConstants'
import DtoAccountHabilitation from '../account/dto/DtoAccountHabilitation'
import RSEauPanel from '../waterManagement/RSEauPanel'
import { convertToRGB } from '../../../utils/ColorUtil'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'
import ToastrAction from '../components/toasters/ToastrAction'
import LoadingCard from '../components/cards/LoadingCard'
import { LightCard } from '../components/styled/grid'
import { getMenuHeight } from '../../../utils/StyleUtils'
import { STATION_TYPE_CONSTANT } from '../referencials/installations/constants/InstallationsConstants'

class PointDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            contactsLoaded: false,
            idInstallation: parseInt(props.match.params.id),
            point: {},
            installation: {},
            openModalEvent: false,
            chroniclePumpId: null,
            chronicleCounterId: null,
            numberOfLines: 5,
            year: new Date().getFullYear(),
            realConso: null,
            selectedEvent: null,
            situationLoaded: false
        }
    }

    componentDidMount() {
        const { mapSituations } = this.props
        this.props.fetchCmsByCategory(5)
        this.props.fetchManagementUnitsRestrictions()
        this.props.fetchDroughtSectorsRestrictions()
        this.props.fetchContacts().then(() => this.setState({ contactsLoaded: true }))
        if (!mapSituations.length) {
            this.props.fetchMapSituations('piezometry').then(() => this.getInstallation())
        } else {
            this.getInstallation()
        }
        window.scrollTo(0, 0)
    }

    getInstallation = () => {
        const { exploitation, mapSituations, mapSituationStats } = this.props
        const { idInstallation, year } = this.state
        this.props.fetchInstallation(idInstallation).then(() => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.fetchVolumes(idInstallation, year))
            } else {
                this.fetchVolumes(idInstallation, year)
            }
        })
        this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
            this.props.fetchMapSituations('piezometry').then(() => {
                const map = mapSituations.find((m) => m.default)
                if (map) {
                    this.props.fetchMapSituationsStationStats('piezometry', map.id).then(() => {
                        if (mapSituationStats?.length) {
                            this.props.fetchMapSituationsResults('piezometry', map, mapSituationStats[0]?.date).then(() => {
                                this.props.fetchAllLinkedStations(exploitation?.link_samplings?.map((l) => l.idInstallation), STATION_TYPE_CONSTANT.installation).then(() => {
                                    this.setState({ situationLoaded: true })
                                })
                            })
                        } else {
                            this.setState({ situationLoaded: true })
                        }
                    })
                } else {
                    this.setState({ situationLoaded: true })
                }
            })
        })
    }

    fetchVolumes = (idInstallation, year) => {
        const { exploitation } = this.props
        this.props.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf()).then((res) => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.setState({ realConso: res?.total, dataLoaded: true }))
            } else {
                this.setState({ realConso: res?.total, dataLoaded: true })
            }
            this.props.fetchBookmarks().then(() => this.setTitle(idInstallation))
        })
    }

    setTitle = (idInstallation) => {
        const { installation, bookmarks } = this.props
        const fav = bookmarks.find((b) => b.stationType === 7 && b.identifiant === `${idInstallation}`)
        const bookmark = {
            login: getLogin(),
            identifiant: `${idInstallation}`,
            stationType: 7,
        }
        this.props.setTitle([{
            title: installation.name || (installation.code ? `${i18n.point} ${installation.code || ''}` : ''),
            href: '/consommation',
            icon: fav ? <Star sx={{ color: mainColor }} onClick={() => this.deleteBookmark(fav, installation)} /> : <StarBorder sx={{ color: mainColor }} onClick={() => this.createBookmark(bookmark, installation)} />,
        }])
    }

    deleteBookmark = (fav, point) => {
        this.props.deleteBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
            ToastrAction.info(`Point ${point.code} retiré des favoris`)
        })
    }

    createBookmark = (fav, point) => {
        this.props.createBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
            ToastrAction.success(`Point ${point.code} ajouté aux favoris.<br /> Il apparaitra en haut de votre liste des points de prélèvement`)
        })
    }

    getMateriels = () => {
        const { variousMateriels, exploitation } = this.props
        const { idInstallation } = this.state
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((c) => [...pumpsIds, ...countersIds].includes(c.id) && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    onDeleteEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        this.props.deleteMatEvent(event.id, () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)).then(() => {
            this.setState({ dataLoaded: true })
        })
    }

    onValidateEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        if (event.id) {
            this.props.updateMatEvent(event,
                () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
            ).then(() => {
                this.setState({ dataLoaded: true })
            })
        } else {
            this.props.createMatEvent(event).then(() => {
                this.props.fetchMatEventsByExploitation(exploitation.idExploitation).then(() => {
                    this.setState({ dataLoaded: true })
                })
            })
        }
    }

    getLastIndexSince = (idInstallation) => {
        const { exploitation } = this.props
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const lastChronicle = orderBy(exploitation.link_chronicles.filter((c) => [...pumpsIds, ...countersIds].includes(c.idMat) && c.measureType === 2), 'measureDate', 'desc')[0]
        if (lastChronicle) {
            return { idMatLastChronicle: lastChronicle.idMat, days: moment().diff(moment(lastChronicle.measureDate), 'days') }
        }
        return { days: 0 }
    }

    hasHabilitation = hab => this.props.accountHabilitations.some(h => h.habilitation === hab)

    render() {
        const { openModalEvent, dataLoaded, contactsLoaded, selectedEvent, year, idInstallation, situationLoaded } = this.state
        const { variousMateriels, variousMatSituations, exploitation, exploitationVolumes, installation, watersheds, managementUnitsRestrictions, matEventsExploitation,
            droughtSectorsRestrictions, applicationSettings, waterTurnsRestrictions, citiesIndex, exploitationWaterTurns, waterTurnsSlots } = this.props
        if (variousMatSituations.length && variousMateriels.length && dataLoaded && contactsLoaded && exploitation.idExploitation) {
            const { pumpsIds, countersIds, materiels } = this.getMateriels()
            // const linkPoint = exploitation.link_samplings.find((p) => p.idInstallation === idInstallation)
            // const exploitationMatIds = materiels.map(({ id }) => id)
            const volumeAuth = (exploitationVolumes || []).filter((v) => v.askedYear === year && v.idInstallation === idInstallation).reduce((acc, v) => acc + (v.authorizedVolume || 0), 0)
            const volumeConsumed = uniqWith((exploitation.link_conso_real || []).filter((c) => c.idInstallation === idInstallation && c.year === moment().year()).map(c => omit(c, 'idInstallation')), (a, b) => a.idMat === b.idMat && a.year === b.year && a.volume === b.volume).reduce((acc, v) => acc + (v.volume || 0), 0)
            const volumeLeft = (volumeAuth - volumeConsumed)
            const percentLeft = (volumeLeft / volumeAuth) * 100
            const sampleCharacteristics = installation?.link_sampleCharacteristics?.[0] || {}
            const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
            const restriction = (() => {
                if (typeRestriction === 1) {
                    return droughtSectorsRestrictions.find((dsR) => dsR.idSector === sampleCharacteristics.droughtSector && sampleCharacteristics.sampleType === dsR.resourceType) || { idRestriction: -1 }
                }
                return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.subManagementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === installation.managementCode && sampleCharacteristics.sampleType === ugeR.resourceType) || { idRestriction: -1 }
            })()
            const waterTurnsRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
            const restrictionLabel = waterTurnsRestriction?.label || i18n.normale
            const restrictionColor = waterTurnsRestriction?.color || '#00AF64'
            const station = this.props.allLinkedStations?.filter(s => s.id === installation.id && s.stationLinkedType === STATION_TYPE_CONSTANT.PIEZOMETRY)[0] || {}
            const situation = this.props.mapSituationResults?.filter(s => s.id === station.stationLinkedId)[0] || {}
            const ipsColor = situation?.trendColor
            const ipsLabel = situation?.trendLabel
            const sampleTypeLabel = sampleCharacteristics?.sampleType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial
            const city = citiesIndex[installation.townCode] || {}
            const { color, max, timeNextSampling } = getColorAndLabelWaterTurn(waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, installation)
            const { idMatLastChronicle, days } = this.getLastIndexSince(installation.id)
            const warning = days > 7
            const groupedEventsByMats = groupBy(matEventsExploitation.filter(({ idMateriel }) => [...pumpsIds, ...countersIds].includes(idMateriel)), 'idMateriel')
            const nbPannes = compact(keys(groupedEventsByMats).map((idMat) => {
                if (orderBy(groupedEventsByMats[idMat], 'eventDate', 'desc')[0]?.idType === 2) {
                    return true
                }
                return null
            })).length
            return (
                <>
                    <Grid container direction='column' justifyContent='center' alignItems='space-around' sx={{ padding: '0 1.5rem 8px 1.5rem', color: mainColor }}>
                        <Grid item xs={8} fontSize={14} sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.5, paddingLeft: '24px' }}>{city?.name ?? ''}</Grid>
                        <Grid item xs={8} fontSize={14} sx={{ whiteSpace: 'pre-wrap', lineHeight: 1.5, paddingLeft: '24px' }}>{installation?.location ?? ''}</Grid>
                        <LightCard
                            item
                            container
                            sx={{ marginBottom: '20px' }}
                        >
                            <Grid item xs={7} container direction='column' fontSize={14}>
                                <Grid item sx={{ marginBottom: '8px' }}>{i18n.samplingResource}</Grid>
                                <Grid item className='bold'>{watersheds.find((w) => w.id === sampleCharacteristics.bvCode)?.name}</Grid>
                                <Grid item className='bold'>{sampleTypeLabel}</Grid>
                            </Grid>
                            {
                                !situationLoaded ?
                                    (
                                        <Grid xs={5} container alignItems='center' justifyContent='center'>
                                            <CircularProgress />
                                        </Grid>
                                    )
                                    :
                                    (
                                        <Grid
                                            item
                                            xs={5}
                                            container
                                            alignItems='center'
                                            justifyContent='space-between'
                                            fontSize={14}
                                            className='bold'
                                            sx={{ border: `1px solid ${ipsColor || 'black'}`, borderRadius: '5px', backgroundColor: `rgba(${convertToRGB(ipsColor || '#000000')}, 0.1)`, padding: '8px 5px' }}
                                        >
                                            <Grid item xs={5}>{getIPSIcon(ipsLabel, ipsColor)}</Grid>
                                            <Grid item xs={7} container direction='column' justifyContent='space-evenly' sx={{ height: '100%' }}>
                                                <Grid item>{i18n.level}</Grid>
                                                <Grid item>{getIPSLabel(ipsLabel)}</Grid>
                                            </Grid>
                                        </Grid>
                                    )
                            }
                        </LightCard>
                        <LightCard
                            item
                            container
                            direction='column'
                            justifyContent='space-between'
                            className='bold clickable'
                            sx={{ marginBottom: '20px' }}
                            onClick={() => this.props.push(`/consommation/point/${idInstallation}/restriction`)}
                        >
                            <Grid item xs={12} fontSize={24}>{i18n.samplingPossibility}</Grid>
                            <Grid container justifyContent='space-between' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', margin: '16px 0' }}>
                                <Grid item xs={12/3} container direction='column' alignItems='flex-start'>
                                    <Grid item className='bold' fontSize={12} sx={{ paddingBottom: '8px' }}>{i18n.situation}</Grid>
                                    <Grid item sx={{ height: '24px', marginBottom: '8px' }}>{getRestrictionIconV2(waterTurnsRestriction?.level, { height: '24px', color: restrictionColor })}</Grid>
                                    <Grid item fontSize={12}>{restrictionLabel}</Grid>
                                </Grid>
                                <Grid item xs={12 - 12/3} container direction='column' alignItems='flex-start'>
                                    <Grid item className='bold' fontSize={12} sx={{ paddingBottom: '8px' }}>{i18n.waterTurn}</Grid>
                                    <Grid item sx={{ height: '24px', marginBottom: '8px' }}>{max ? <CancelOutlined sx={{ color: darkRed }} /> : <CheckCircleOutlined sx={{ color }} /> }</Grid>
                                    <Grid item fontSize={12}>{timeNextSampling ? template(i18n.nextSamplingInXHours)({ hours: timeNextSampling }) : ''}</Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent='flex-end'
                                alignItems='center'
                                sx={{ color: mainColor }}
                                className='bold'
                            >
                                {i18n.seeCalendar}<ArrowForwardIos fontSize='20px' sx={{ marginLeft: '10px' }} />
                            </Grid>
                        </LightCard>
                        <LightCard
                            item
                            container
                            direction='column'
                            justifyContent='space-between'
                            className='bold clickable'
                            sx={{ marginBottom: '20px' }}
                            onClick={() => this.props.push(`/consommation/point/${idInstallation}/details`)}
                        >
                            <Grid item xs={12} fontSize={24}>{i18n.samplingVolume}</Grid>
                            <Grid container justifyContent='space-between' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', margin: '16px 0' }}>
                                <Grid item xs={4} container direction='column' alignItems='flex-start'>
                                    <Grid item className='bold' fontSize={22} sx={{ height: '24px', marginBottom: '8px' }}>{formatMilliers(volumeAuth)}</Grid>
                                    <Grid item fontSize={12}>{i18n.authorizedVolume}</Grid>
                                </Grid>
                                <Grid item xs={7} container direction='column' alignItems='flex-start'>
                                    <Grid item className='bold' fontSize={22} sx={{ height: '24px', marginBottom: '8px' }}>{volumeAuth ? `${percentLeft <= 0 ? 0 : round(percentLeft)}%` : '-'}</Grid>
                                    <Grid item fontSize={12}>{i18n.remainingVolume}</Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent='flex-end'
                                alignItems='center'
                                sx={{ color: mainColor }}
                                className='bold'
                            >
                                {i18n.seeConsumption}<ArrowForwardIos fontSize='20px' sx={{ marginLeft: '10px' }} />
                            </Grid>
                        </LightCard>
                        <LightCard
                            item
                            container
                            direction='column'
                            justifyContent='space-between'
                            className='bold clickable'
                            sx={{ marginBottom: '10px', paddingBottom: '10px' }}
                            onClick={() => this.props.push(`/consommation/point/${idInstallation}/events`)}
                        >
                            <Grid item xs={12} fontSize={24}>{i18n.materialsState}</Grid>
                            <Grid container direction='column' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', margin: '16px 0 8px' }}>
                                <Grid item className='bold' fontSize={22} sx={{ height: '24px', marginBottom: '8px' }}>{nbPannes}</Grid>
                                <Grid item fontSize={12}>{i18n.breakdownInProgress}</Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{ color: mainColor }}
                                className='bold'
                            >
                                <Grid item xs={5.5}>
                                    <MainButton
                                    reverse
                                    sx={{ fontSize: '12px', padding: '4px 12px' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.props.push(`/consommation/point/${idInstallation}/events/materiels`)
                                    }}
                                >
                                        {i18n.reportIncident}
                                    </MainButton>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    {i18n.seeDetails}<ArrowForwardIos fontSize='20px' sx={{ marginLeft: '10px' }} />
                                </Grid>
                            </Grid>
                        </LightCard>
                        <Grid item style={{ position: 'sticky', bottom: getMenuHeight() }}>
                            {warning ? (
                                <RedButton startIcon={<Error />} sx={{ fontSize: '14px' }} onClick={() => this.props.push(`/index/${idInstallation}/materiels/${idMatLastChronicle}/entries`)}>{template(i18n.noIndexSinceXDays)({ days })}</RedButton>
                            ) : (
                                <MainButton onClick={() => this.props.push(`/index/${idInstallation}/materiels`)}>
                                    {i18n.enterIndex}
                                </MainButton>
                            )}
                        </Grid>
                        {this.hasHabilitation(AGRI) && this.hasHabilitation(AGRI_RSEAU) && (
                            <RSEauPanel idInstallation={idInstallation} />
                        )}
                    </Grid>
                    {openModalEvent && (
                        <ModalMatEvent
                            open={openModalEvent}
                            event={selectedEvent}
                            materiels={materiels}
                            onCancel={() => this.setState({ openModalEvent: false, selectedEvent: null })}
                            onDelete={this.onDeleteEvent}
                            onValidate={this.onValidateEvent}
                        />
                    )}
                </>
            )
        }
        return <LoadingCard />
    }
}

PointDashboard.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    fetchInstallation: PropTypes.func,
    fetchExploitation: PropTypes.func,
    updateExploitation: PropTypes.func,
    fetchCmsByCategory: PropTypes.func,
    createMatEvent: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    calculPointVolumeReal: PropTypes.func,
    deleteMatEvent: PropTypes.func,
    updateMatEvent: PropTypes.func,
    resetInstallation: PropTypes.func,
    fetchAllLinkedStations: PropTypes.func,
    fetchContacts: PropTypes.func,
    deleteBookmark: PropTypes.func,
    createBookmark: PropTypes.func,
    fetchBookmarks: PropTypes.func,
    fetchMapSituationsStation: PropTypes.func,
    fetchMapSituationsStationStats: PropTypes.func,
    fetchMapSituationsResults: PropTypes.func,
    fetchMapSituations: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    mapSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoMapSituation)),
    linkedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAssociatedStation)),
    mapSituationStation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMapSituationResult)),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    matEventsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEvent)),
    bookmarks: PropTypes.arrayOf(PropTypes.shape({})),
    watersheds: PropTypes.arrayOf(PropTypes.shape({})),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    managementUnitsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnitRestriction)),
    droughtSectorsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoDroughtSectorRestriction)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
    mapSituationStats: PropTypes.instanceOf({}),
    allLinkedStations: PropTypes.instanceOf({}),
    mapSituationResults: PropTypes.instanceOf({}),
}

const mapStateToProps = (store) => {
    return {
        installations: store.InstallationsReducer.installations,
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        mapSituations: store.InstallationsReducer.mapSituations,
        linkedStations: store.InstallationsReducer.linkedStations,
        mapSituationStation: store.InstallationsReducer.mapSituationStation,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        cms: store.CmsReducer.cms,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        bookmarks: store.ReferencialReducer.bookmarks,
        watersheds: store.ReferencialReducer.watersheds,
        citiesIndex: store.CityReducer.citiesIndex,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        mapSituationStats: store.InstallationsReducer.mapSituationStats,
        allLinkedStations: store.InstallationsReducer.allLinkedStations,
        mapSituationResults: store.InstallationsReducer.mapSituationResults,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    updateExploitation: AgriAction.updateExploitation,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchInstallation: InstallationsAction.fetchInstallation,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    fetchMapSituations: InstallationsAction.fetchMapSituations,
    fetchMapSituationsStation: InstallationsAction.fetchMapSituationsStation,
    fetchMapSituationsStationStats: InstallationsAction.fetchMapSituationsStationStats,
    fetchMapSituationsResults: InstallationsAction.fetchMapSituationsResults,
    fetchAllLinkedStations: InstallationsAction.fetchAllLinkedStations,
    resetInstallation: InstallationsAction.resetInstallation,
    setTitle: HomeAction.setTitle,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
    createMatEvent: MaterielAction.createMatEvent,
    updateMatEvent: MaterielAction.updateMatEvent,
    deleteMatEvent: MaterielAction.deleteMatEvent,
    fetchMatEventsByExploitation: MaterielAction.fetchMatEventsByExploitation,
    calculPointVolumeReal: AgriAction.calculPointVolumeReal,
    fetchContacts: ContactAction.fetchContacts,
    deleteBookmark: ReferencialAction.deleteBookmark,
    createBookmark: ReferencialAction.createBookmark,
    fetchBookmarks: ReferencialAction.fetchBookmarks,
    fetchManagementUnitsRestrictions: ReferencialAction.fetchManagementUnitsRestrictions,
    fetchDroughtSectorsRestrictions: AgriAction.fetchDroughtSectorsRestrictions,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointDashboard)
