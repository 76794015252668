import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import { orderPointsList } from '../../../utils/ObjectUtils'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import { formatDate } from '../../../utils/DateUtil'
import { formatMilliers } from '../../../utils/StringUtil'
import { hasValue } from '../../../utils/NumberUtil'
import moment from 'moment'
import { orderBy, template } from 'lodash'
import { Error } from '@mui/icons-material'
import { darkRed, otherBlue } from '../components/styled/theme'
import { RedButton } from '../components/styled/buttons'
import HomeAction from '../../offline/actions/HomeAction'
import { convertToRGB } from '../../../utils/ColorUtil'
import LoadingCard from '../components/cards/LoadingCard'
import { LightCard } from '../components/styled/grid'

const IndexApp = ({}) => {
    const [pointsLoaded, setPointsLoaded] = useState(false)

    const dispatch = useDispatch()

    const {
        exploitation,
        installations,
        citiesIndex,
        variousMateriels,
        variousMatSituations,
    } = useSelector(store => ({
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
    }), shallowEqual)

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.index,
            href: 'index',
        }]))
    }, [])

    useEffect(() => {
        if (exploitation.idExploitation) {
            if (!installations.length) {
                dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation.idExploitation)).then(() => {
                    setPointsLoaded(true)
                })
            } else {
                setPointsLoaded(true)
            }
        }
    }, [exploitation, installations])

    const getMateriels = (pointId) => {
        const pointPumps = variousMatSituations.filter((m) => m.siteType === 7 && m.siteCode === pointId) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((m) => [...pumpsIds, ...countersIds].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    const getLastChronicle = (point) => {
        const { materiels } = getMateriels(point.id)
        const pointChronicles = exploitation?.link_chronicles?.filter(({ idMat, measureDate }) => materiels.map(({ id }) => id).includes(idMat) &&
            (!point.startDate || measureDate >= point.startDate) &&
            (!point.endDate || measureDate < point.endDate)
        ) || []
        const lastChronicle = pointChronicles.length ? pointChronicles.reduce((acc, chronique) => (acc.measureDate > chronique.measureDate) ? acc : chronique) : null
        if (lastChronicle && lastChronicle.measureType !== 1) {
            return (
                <Grid
                    item
                    sx={{
                        border: `1px solid ${otherBlue}`,
                        borderRadius: '8px',
                        padding: '6px 12px',
                        fontSize: '12px',
                        color: otherBlue,
                        width: 'fit-content',
                        marginBottom: '16px'
                    }}
                >
                    {`${i18n.lastIndexEntryAt}${formatDate(lastChronicle.measureDate)} : ${formatMilliers(lastChronicle.value) || 0}`}
                </Grid>
            )
        }
        return null
    }

    const getLastIndexSince = (idInstallation) => {
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const lastChronicle = orderBy(exploitation?.link_chronicles?.filter((c) => [...pumpsIds, ...countersIds].includes(c.idMat) && c.measureType === 2) || [], 'measureDate', 'desc')[0]
        if (lastChronicle) {
            return moment().diff(moment(lastChronicle.measureDate), 'days')
        }
        return 0
    }

    const getPoints = () => {
        const pointsUsed = []
        const pointsNotUsed = []
        const pointsDeleted = []
        exploitation.link_samplings.forEach((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                const days = getLastIndexSince(point.id)
                const warning = days >= 7
                const pointFormatted = {
                    ...point,
                    days,
                    warning,
                    cityName: city.name,

                }
                switch (linkPoint.stateCode) {
                    case 1:
                        pointsUsed.push(pointFormatted)
                        break
                    case 2:
                        pointsNotUsed.push(pointFormatted)
                        break
                    case 3:
                        pointsDeleted.push(pointFormatted)
                        break
                    default:
                        pointsNotUsed.push(pointFormatted)
                }
            }
        })
        return {
            pointsUsed: orderPointsList(pointsUsed),
            pointsNotUsed: orderPointsList(pointsNotUsed),
            pointsDeleted: orderPointsList(pointsDeleted),
        }
    }

    if (pointsLoaded && variousMatSituations.length) {
        const { pointsUsed, pointsNotUsed } = getPoints()
        const pointsOrdered = orderBy([...pointsUsed, ...pointsNotUsed], ['warning', 'days'], ['desc', 'desc'])
        return (
            <div style={{ padding: '8px 1.5rem' }}>
                <Grid container>
                    {pointsOrdered.map((p) => (
                        <LightCard
                            key={p.id}
                            container
                            direction='column'
                            className='clickable'
                            onClick={() => dispatch(push(`/index/${p.id}/materiels`))}
                            sx={{ marginBottom: '10px', border: p.warning ? `1px solid ${darkRed}` : 'none' }}
                        >
                            {p.warning ? (
                                <RedButton
                                    startIcon={<Error />}
                                    noFullWidth={true}
                                    sx={{
                                        minHeight: '32px',
                                        marginTop: 0,
                                        fontSize: '12px',
                                        marginBottom: '16px',
                                        color: darkRed,
                                        backgroundColor: `rgba(${convertToRGB(darkRed)}, 0.1)`,
                                        border: `1px solid ${darkRed}`,
                                        borderRadius: '8px',
                                        '&:disabled': {
                                            color: darkRed,
                                            backgroundColor: `rgba(${convertToRGB(darkRed)}, 0.1)`,
                                            border: `1px solid ${darkRed}`,
                                            borderRadius: '8px',
                                        },
                                    }}
                                    disabled
                                >
                                    {template(i18n.noIndexSinceXDaysShort)({ days: p.days })}
                                </RedButton>
                            ) : getLastChronicle(p)}
                            <Grid item container justifyContent='space-between'>
                                <Grid item className='bold' fontSize={24}>{p.name || (p.code ? `${i18n.point} ${p.code || ''}` : '')}</Grid>
                            </Grid>
                            <Grid item container justifyContent='space-between' sx={{ marginTop: '20px' }}>
                                <Grid item fontSize={14}>{i18n.city}</Grid>
                                <Grid item fontSize={14} className='bold'>{citiesIndex[p.townCode]?.name || ''}</Grid>
                            </Grid>
                            <Grid item container justifyContent='space-between' className='padding-top-2'>
                                <Grid item fontSize={14}>{i18n.localisation}</Grid>
                                <Grid item fontSize={14} className='bold'>{p?.location || ''}</Grid>
                            </Grid>
                            <Grid item container justifyContent='space-between' className='padding-top-2'>
                                <Grid item fontSize={14}>{i18n.codeParcelle}</Grid>
                                <Grid item fontSize={14} className='bold'>{p?.parcel && p?.section && `${p.parcel} ${p.section}`}</Grid>
                            </Grid>
                        </LightCard>
                    )
                    )}
                </Grid>
            </div>
        )
    }
    return <LoadingCard />
}

IndexApp.propTypes = {
    push: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
}

export default IndexApp
