import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import MyDocuments from '../documents/MyDocuments'
import HomeAction from '../../offline/actions/HomeAction'
import { useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'
import MyDocumentsDesktop from '../documents/MyDocumentsDesktop'

const DocumentsApp = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.documents,
            href: '/documents'
        }]))
    }, [])

    return (
        isMobile ? <MyDocuments /> : <MyDocumentsDesktop />
    )
}

export default DocumentsApp
