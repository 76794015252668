import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { sortById } from '../utils/ObjectUtils'
import CmsAction from './online/cms/actions/CmsAction'
import DtoCMSEvent from './online/cms/dto/DtoCMSEvent'
import HomeAction from './offline/actions/HomeAction'
import LoadingCard from './online/components/cards/LoadingCard'
import { mainColor } from './online/components/styled/theme'
import { LightCard } from './online/components/styled/grid'

class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cmsLoaded: false,
        }
    }

    componentDidMount() {
        this.getCMS()
        this.props.setTitle([{
            title: i18n.legalInfos,
            href: '/myaccount/about',
        }])
    }

    getCMS = () => {
        const { cmsEvents } = this.props
        if (!cmsEvents.length) {
            this.setState({ cmsLoaded: false })
            this.props.fetchCMSEvents().then(() => {
                this.setState({ cmsLoaded: true })
            })
        }
    }

    showCMS = (category) => {
        const { cmsEvents = [] } = this.props
        const filteredCms = cmsEvents.filter((cms) => cms.idCategory === category)
        filteredCms.sort(sortById)

        return filteredCms.map((cms) => {
            return (
                <LightCard key={cms.id} item xs={12} sx={{ marginBottom: '10px' }}>
                    <Grid item fontSize={22} sx={{ paddingBottom: '8px' }}>{cms.title}</Grid>
                    <div dangerouslySetInnerHTML={{ __html: cms.comment || '' }} />
                </LightCard>
            )
        })
    }

    render() {
        const { cmsLoaded } = this.state

        if (cmsLoaded) {
            return (
                <Grid container sx={{ padding: '8px 1.5rem', color: mainColor }}>
                    {this.showCMS(1)}
                    {this.showCMS(3)}
                </Grid>
            )
        }
        return <LoadingCard />
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchCMSEvents: CmsAction.fetchCMSEvents,
}

const mapStateToProps = (store) => {
    return {
        cmsEvents: store.CmsReducer.cmsEvents,
    }
}

About.propTypes = {
    fetchCMSEvents: PropTypes.func,
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    setTitle: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(About)
