import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import AgriAction from '../agri/actions/AgriAction'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import { orderPointsList } from '../../../utils/ObjectUtils'
import DtoCity from '../referencials/city/dto/DtoCity'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import { formatDate } from '../../../utils/DateUtil'
import { formatMilliers } from '../../../utils/StringUtil'
import { hasValue } from '../../../utils/NumberUtil'
import DtoWaterTurn from '../agri/dto/DtoWaterTurn'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import DtoWaterTurnsSlot from '../agri/dto/DtoWaterTurnsSlot'
import { getColorAndLabelWaterTurn, getRestrictionIconV2 } from '../../../utils/AgriUtils'
import { getDistance, getDistanceLabel } from '../../../utils/mapUtils/CoordinateUtils'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import moment from 'moment'
import { omit, orderBy, partition, round, template, uniqWith } from 'lodash'
import DtoManagementUnitRestriction from '../referencials/dto/DtoManagementUnitRestriction'
import DtoDroughtSectorRestriction from '../agri/dto/DtoDroughtSectorRestriction'
import { getSettingInt } from '../../../utils/FormUtils'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import { CancelOutlined, CheckCircleOutlined, Error, Star } from '@mui/icons-material'
import { darkRed } from '../components/styled/theme'
import { RedButton } from '../components/styled/buttons'
import LoadingCard from '../components/cards/LoadingCard'
import { LightCard } from '../components/styled/grid'
import { isMobile } from 'react-device-detect'

class PointsListApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pointsLoaded: false,
        }
    }

    componentDidMount() {
        this.props.setTitle([{
            title: i18n.installations,
            href: 'consommation',
        }])
        this.getExploitation()
    }

    getExploitation = () => {
        const { exploitation } = this.props
        this.props.fetchManagementUnitsRestrictions()
        this.props.fetchDroughtSectorsRestrictions()

        if (exploitation?.idExploitation) {
            this.getData()
        } else {
            this.props.fetchExploitation().then(() => this.getData())
        }
    }

    getData = () => {
        const { exploitation, installations } = this.props
        if (!installations.length) {
            this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
                this.setState({ pointsLoaded: true })
            })
        } else {
            this.setState({ pointsLoaded: true })
        }
    }

    getMateriels = (pointId) => {
        const { variousMatSituations, variousMateriels, exploitation } = this.props
        const pointPumps = variousMatSituations.filter((m) => m.siteType === 7 && m.siteCode === pointId) || []
        const pointCounters = variousMatSituations.filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((m) => [...pumpsIds, ...countersIds].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    getLastChronicle = (point) => {
        const { exploitation } = this.props
        const { materiels } = this.getMateriels(point.id)
        const pointChronicles = exploitation.link_chronicles.filter(({ idMat, measureDate }) => materiels.map(({ id }) => id).includes(idMat) &&
            (!point.startDate || measureDate >= point.startDate) &&
            (!point.endDate || measureDate < point.endDate)
        )
        const lastChronicle = pointChronicles.length ? pointChronicles.reduce((acc, chronique) => (acc.measureDate > chronique.measureDate) ? acc : chronique) : null
        if (lastChronicle) {
            const matFound = materiels.find((m) => m.id === lastChronicle.idMat) || {}
            return (
                <>
                    {lastChronicle.measureType === 1 ? (
                        <>
                            <b>{matFound.reference || i18n.unknown}</b>
                            <span>{` - ${formatDate(lastChronicle.measureDate)} -> ${formatDate(lastChronicle.endDate)} : ${formatMilliers(lastChronicle.value) || 0} m3`}</span>
                        </>
                    ) : (
                        <>
                            <b>{matFound.reference || i18n.unknown}</b>
                            <span>{` - ${formatDate(lastChronicle.measureDate)} : ${formatMilliers(lastChronicle.value) || 0}`}</span>
                        </>
                    )}
                </>
            )
        }
        return <span>{i18n.noConsumptionIndicated}</span>
    }

    getLastIndexSince = (idInstallation) => {
        const { exploitation } = this.props
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const lastChronicle = orderBy(exploitation.link_chronicles.filter((c) => [...pumpsIds, ...countersIds].includes(c.idMat) && c.measureType === 2), 'measureDate', 'desc')[0]
        if (lastChronicle) {
            return { idMatLastChronicle: lastChronicle.idMat, days: moment().diff(moment(lastChronicle.measureDate), 'days') }
        }
        return { days: 0 }
    }

    getPoints = () => {
        const { exploitation, installations, citiesIndex, exploitationVolumes, managementUnitsRestrictions, droughtSectorsRestrictions, waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, applicationSettings } = this.props
        const pointsUsed = []
        const pointsNotUsed = []
        const pointsDeleted = []
        const year = moment().year()
        exploitation.link_samplings.forEach((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const volumeAuth = exploitationVolumes.filter((v) => v.askedYear === year && v.idInstallation === point.id && hasValue(v.authorizedVolume)).reduce((acc, v) => acc + v.authorizedVolume, 0)
                const volumeConsumed = uniqWith(exploitation.link_conso_real.filter((c) => c.idInstallation === point.id && c.year === moment().year()).map(c => omit(c, 'idInstallation')), (a, b) => a.idMat === b.idMat && a.year === b.year && a.volume === b.volume).reduce((acc, v) => acc + (v.volume || 0), 0)
                const percentLeft = ((volumeAuth - volumeConsumed) / volumeAuth) * 100
                const city = citiesIndex[point.townCode] || {}
                const long = localStorage.getItem('LONG')
                const lat = localStorage.getItem('LAT')
                const { color, max, timeNextSampling } = getColorAndLabelWaterTurn(waterTurnsRestrictions, exploitationWaterTurns, waterTurnsSlots, point)
                const typeRestriction = getSettingInt(applicationSettings, 'agriTypeRestriction') || 1
                const restriction = (() => {
                    if (typeRestriction === 1) {
                        return droughtSectorsRestrictions.find((dsR) => dsR.idSector === point.droughtSector && point.sampleType === dsR.resourceType) || { idRestriction: -1 }
                    }
                    return managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.subManagementCode && point.sampleType === ugeR.resourceType) || managementUnitsRestrictions.find((ugeR) => ugeR.managementCode === point.managementCode && point.sampleType === ugeR.resourceType) || { idRestriction: -1 }
                })()
                const waterTurnsRestriction = waterTurnsRestrictions.find((r) => r.id === restriction.idRestriction)
                const restrictionLabel = waterTurnsRestriction?.label || i18n.normale
                const restrictionColor = waterTurnsRestriction?.color || '#00AF64'
                const { idMatLastChronicle, days } = this.getLastIndexSince(point.id)
                const warning = days > 7
                const pointFormatted = {
                    ...point,
                    days: warning ? days : 0,
                    idMatLastChronicle,
                    warning,
                    cityName: city.name,
                    distance: point.x && point.y && point.projection && lat && long ? getDistance({
                        projection: 16,
                        x: long,
                        y: lat
                    }, point) : null,
                    footerContent: (
                        <Grid container justifyContent='space-between' sx={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px', marginTop: '16px' }}>
                            <Grid item xs={3.5} container direction='column' alignItems='flex-start'>
                                <Grid item className='bold' fontSize={12} sx={{ paddingBottom: '8px' }}>{i18n.consoShort}</Grid>
                                <Grid item className='bold' fontSize={22} sx={{ height: '24px', marginBottom: '8px' }}>{volumeAuth ? `${percentLeft <= 0 ? 0 : round(percentLeft)}%` : '-'}</Grid>
                                <Grid item fontSize={12}>{i18n.remaining}</Grid>
                            </Grid>
                            <Grid item xs={3.5} container direction='column' alignItems='flex-start'>
                                <Grid item className='bold' fontSize={12} sx={{ paddingBottom: '8px' }}>{i18n.situation}</Grid>
                                <Grid item sx={{ height: '24px', marginBottom: '8px' }}>{getRestrictionIconV2(waterTurnsRestriction?.level, { height: '24px', color: restrictionColor })}</Grid>
                                <Grid item fontSize={12}>{restrictionLabel}</Grid>
                            </Grid>
                            <Grid item xs={5} container direction='column' alignItems='flex-start'>
                                <Grid item className='bold' fontSize={12} sx={{ paddingBottom: '8px' }}>{i18n.waterTurn}</Grid>
                                <Grid item sx={{ height: '24px', marginBottom: '8px' }}>{max ? <CancelOutlined sx={{ color: darkRed }} /> : <CheckCircleOutlined sx={{ color }} /> }</Grid>
                                <Grid item fontSize={12}>{timeNextSampling ? template(i18n.nextSamplingInXHours)({ hours: timeNextSampling }) : ''}</Grid>
                            </Grid>
                        </Grid>
                    )
                }
                switch (linkPoint.stateCode) {
                    case 1:
                        pointsUsed.push(pointFormatted)
                        break
                    case 2:
                        pointsNotUsed.push(pointFormatted)
                        break
                    case 3:
                        pointsDeleted.push(pointFormatted)
                        break
                    default:
                        pointsNotUsed.push(pointFormatted)
                }
            }
        })
        return {
            pointsUsed: orderPointsList(pointsUsed),
            pointsNotUsed: orderPointsList(pointsNotUsed),
            pointsDeleted: orderPointsList(pointsDeleted),
        }
    }

    render() {
        const { variousMatSituations, citiesIndex, bookmarks } = this.props
        const { pointsLoaded } = this.state
        if (pointsLoaded && variousMatSituations.length) {
            const { pointsUsed, pointsNotUsed } = this.getPoints()
            const [pointsFav, othersPoints] = partition([...pointsUsed, ...pointsNotUsed], (p) => bookmarks.some((b) => b.stationType === 7 && b.identifiant === `${p.id}`))
            const pointsOrdered = orderBy([...pointsFav.map((p) => ({ ...p, fav: true })), ...othersPoints], ['warning', 'days', 'fav', 'distance'], ['desc', 'desc', 'asc', 'asc'])

            return (
                <div style={{ padding: '8px 1.5rem' }}>
                    <Grid container>
                        {pointsOrdered.map((p) => (
                            <LightCard
                                key={p.id}
                                container
                                direction='column'
                                className='clickable'
                                // TODO
                                onClick={() => this.props.push(`${isMobile ? '/consommation': '/installations'}/point/${p.id}`)}
                                sx={{ marginBottom: '10px', border: p.warning ? `1px solid ${darkRed}` : 'none' }}
                            >
                                {p.warning &&(
                                    <RedButton
                                        startIcon={<Error />}
                                        noFullWidth={true}
                                        sx={{
                                            minHeight: '32px',
                                            fontSize: '12px',
                                            marginBottom: '16px',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.props.push(`/index/${p.id}/materiels/${p.idMatLastChronicle}/entries`)
                                        }}
                                    >
                                        {template(i18n.noIndexSinceXDays)({ days: p.days })}
                                    </RedButton>
                                )}
                                <Grid item container justifyContent='space-between' className='padding-top-2'>
                                    <Grid item className='bold' fontSize={24}>{p.name || (p.code ? `${i18n.point} ${p.code || ''}` : '')}</Grid>
                                    <Grid item>{bookmarks.some((b) => b.stationType === 7 && b.identifiant === `${p.id}`) && <Star />}</Grid>
                                </Grid>
                                <Grid item container justifyContent='space-between' className='padding-top-2'>
                                    <Grid item fontSize={14}>{citiesIndex[p.townCode]?.name || ''}</Grid>
                                    <Grid item>{p.distance ? `${i18n.at} ${getDistanceLabel(p.distance)}` : '-'}</Grid>
                                </Grid>
                                <Grid item className='padding-top-1' fontSize={14}>{p.location || ''}</Grid>
                                {p.footerContent}
                            </LightCard>
                        )
                        )}
                    </Grid>
                </div>
            )
        }
        return <LoadingCard />
    }
}

PointsListApp.propTypes = {
    setTitle: PropTypes.func,
    fetchExploitation: PropTypes.func,
    fetchInstallationsByExploitationId: PropTypes.func,
    fetchLastDeclarationByExploitationId: PropTypes.func,
    push: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(DtoCity)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    exploitationWaterTurns: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurn)),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
    waterTurnsSlots: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnsSlot)),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    managementUnitsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnitRestriction)),
    droughtSectorsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoDroughtSectorRestriction)),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
    bookmarks: PropTypes.arrayOf(PropTypes.shape({})),
    fetchManagementUnitsRestrictions: PropTypes.func,
    fetchDroughtSectorsRestrictions: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        installations: store.InstallationsReducer.installations,
        codesSandre: store.ReferencialReducer.codesSandre,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        applicationSettings: store.HomeReducer.applicationSettings,
        managementUnitsRestrictions: store.ReferencialReducer.managementUnitsRestrictions,
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitationWaterTurns: store.AgriReducer.exploitationWaterTurns,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        bookmarks: store.ReferencialReducer.bookmarks,
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    fetchManagementUnitsRestrictions: ReferencialAction.fetchManagementUnitsRestrictions,
    fetchDroughtSectorsRestrictions: AgriAction.fetchDroughtSectorsRestrictions,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointsListApp)
