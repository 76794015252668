import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import ToastrAction from '../components/toasters/ToastrAction'
import { MainButton, TextButton } from '../components/styled/buttons'
import { isValidEmail, isValidPhone } from '../../../utils/FormUtils'
import AccountAction from './actions/AccountAction'
import { mainColor, textColor } from '../components/styled/theme'
import { getDate } from '../../../utils/DateUtil'
import { ArrowForwardIos, EditOutlined, FolderOutlined } from '@mui/icons-material'
import { LightCard } from '../components/styled/grid'
import { InputRow } from '../components/styled/inputs'
import LoadingCard from '../components/cards/LoadingCard'
import useProgressDispatch from '../../../utils/customHooks/useProgressDispatch'
import ModalUpdatePasswordDesktop from './components/ModalUpdatePasswordDesktop'
import { formatPhone } from '../../../utils/StringUtil'

const MyAccountDesktop = () => {
    const [openModal, setOpenModal] = useState(false)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
    const [editMode, setEditMode] = useState('')

    const isUnvalidEmail = useMemo(() => editMode && email.length > 0 && !isValidEmail(email), [email])
    const isUnvalidPhone = useMemo(() => editMode && phone.length > 0 && !isValidPhone(phone), [phone])

    const {
        accountUser,
        contact,
        dateValidCgu,
        applicationSettings,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        contact: store.ContactReducer.contact,
        dateValidCgu: store.HomeReducer.dateValidCgu,
        applicationSettings: store.HomeReducer.applicationSettings,
    }), shallowEqual)

    const dispatch = useDispatch()

    const { isLoaded } = useProgressDispatch(() => {
        const contactPromise = accountUser.contactCode ? [
            dispatch(ContactAction.fetchContact(accountUser.contactCode)).then(() => {
                setName(contact.name || '')
                setPhone(contact.phoneTel || '')
                setEmail(contact.email || '')
            })
        ] : []

        return [
            dispatch(HomeAction.getDateValidCGU(getLogin())),
            ...contactPromise,
        ]
    })

    const toggleModal = () => {
        setOpenModal(!openModal)
    }

    const onSavePassword = () => {
        const login = getLogin()
        if (password) {
            dispatch(HomeAction.login(login, password)).then(() => {
                if (
                    newPassword !== null &&
                    newPassword.length &&
                    newPasswordConfirmation !== null &&
                    newPasswordConfirmation.length
                ) {
                    if (newPassword === newPasswordConfirmation) {
                        const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                        if (regex.test(newPassword)) {
                            if (newPassword !== login) {
                                dispatch(AccountAction.changePassword({ password: btoa(decodeURIComponent(encodeURIComponent(newPassword))) }))
                                toggleModal()
                            } else {
                                ToastrAction.error(i18n.passwordNeedToBeDifferent, true)
                            }
                        } else {
                            ToastrAction.error(regexHelp, true)
                        }
                    } else {
                        ToastrAction.error(i18n.passwordAreNotTheSame, true)
                    }
                }
            })
        }
    }

    const handlePhoneChange = (newPhoneNumber) => {
        if (newPhoneNumber.length < 11 || newPhoneNumber.length < phone.length) {
            setPhone(newPhoneNumber)
        }
    }

    const onSubmit = () => {
        const newContact = { ...contact, name, phoneTel: phone, email }
        if (!isUnvalidEmail && !isUnvalidPhone) {
            dispatch(ContactAction.updateContact(newContact)).then(() => {
                setEditMode(false)
            })
        }
    }

    if (!isLoaded) {
        return <LoadingCard />
    }

    return (
        <Grid
            container
            alignContent='flex-start'
            rowGap='5vh'
            sx={{
                padding: '5vh',
                height: 'calc(100% - 4vh)',
                width: '100%',
                overflowY: 'hidden',
                color: textColor,
            }}
        >
            <Grid item xs={12} container alignItems='center' flexWrap='nowrap' justifyContent='space-between' >
                <Grid item sx={{ fontSize: '22px', lineHeight: '28px' }}>{i18n.account}</Grid>
                {(accountUser.isAdmin === '1' || accountUser.metadata === '1') && (
                    <MainButton
                        noFullWidth
                        onClick={() => dispatch(push('/admin'))}
                        startIcon={<FolderOutlined sx={{ fontSize: '16px' }} />}
                        sx={{
                            margin: '0',
                            minHeight: '28px',
                            width: 'auto',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                    >
                        {i18n.selectFolder}
                    </MainButton>
                )}
            </Grid>
            <Grid item container xs={12} rowGap='1.5vh' sx={{ height: '70vh', overflowY: 'auto' }}>
                <LightCard
                    container
                    rowGap='3vh'
                    justifyContent='space-between'
                    sx={{ padding: '4.5vh' }}
                >
                    <Grid item xs={12} container justifyContent='space-between'>
                        <Grid item fontSize={22}>{i18n.ids}</Grid>
                        {!editMode && (
                            <TextButton noFullWidth={true} sx={{ margin: 0 }} onClick={() => setEditMode(true)} endIcon={<EditOutlined />}>
                                {i18n.changeInfos}
                            </TextButton>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent='space-between'
                        sx={{ color: mainColor }}
                    >
                        <Grid item xs={5.9}>
                            <InputRow
                                noFullWidth
                                disabled={!editMode}
                                item
                                xs={5.9}
                                id='name'
                                label={i18n.name}
                                type='text'
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                variant='outlined'
                                onKeyDown={(e) => e.key === 'Enter' ? onSubmit() : null}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: mainColor,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={5.9} />
                        <Grid item xs={5.9}>
                            <InputRow
                                noFullWidth
                                disabled={!editMode}
                                item
                                xs={5.9}
                                id='mail'
                                label={i18n.mailAddress}
                                type='text'
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                variant='outlined'
                                onKeyDown={(e) => e.key === 'Enter' ? onSubmit() : null}
                                error={isUnvalidEmail}
                                helperText={isUnvalidEmail ? i18n.unvalidAddress : ''}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: mainColor,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={5.9}>
                            <InputRow
                                noFullWidth
                                disabled={!editMode}
                                item
                                xs={5.9}
                                id='tel'
                                label={i18n.phoneTel}
                                type='text'
                                value={!editMode ? formatPhone(phone) : phone}
                                onChange={(event) => handlePhoneChange(event.target.value)}
                                variant='outlined'
                                onKeyDown={(e) => e.key === 'Enter' ? onSubmit() : null}
                                error={isUnvalidPhone}
                                helperText={isUnvalidPhone ? i18n.unvalidPhone : ''}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: mainColor,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    {editMode && (
                        <Grid container alignItems='center' justifyContent='flex-end' columnGap='1vw'>
                            <Grid item>
                                <TextButton sx={{ color: 'black', margin: 0 }} onClick={() => {
                                    setEditMode(false)
                                    setName(contact.name || '')
                                    setPhone(contact.phoneTel || '')
                                    setEmail(contact.email || '')
                                }}>
                                    {i18n.abandon}
                                </TextButton>
                            </Grid>
                            <Grid item>
                                <MainButton onClick={onSubmit}>
                                    {i18n.saveChanges}
                                </MainButton>
                            </Grid>
                        </Grid>
                    )}
                </LightCard>
                <LightCard
                    container
                    rowGap='3vh'
                    justifyContent='space-between'
                    sx={{ padding: '4.5vh' }}
                >
                    <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                        <Grid item fontSize={22}>{i18n.password}</Grid>
                        <TextButton noFullWidth={true} sx={{ margin: 0 }} onClick={toggleModal} endIcon={<EditOutlined />}>
                            {i18n.changePassword}
                        </TextButton>
                    </Grid>
                </LightCard>
                <LightCard
                    container
                    rowGap='3vh'
                    justifyContent='space-between'
                    sx={{ padding: '4.5vh' }}
                >
                    <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                        <Grid item fontSize={22}>{i18n.CGU}</Grid>
                        <TextButton noFullWidth={true} sx={{ margin: 0 }} onClick={() => dispatch(push('/myaccount/about'))} endIcon={<ArrowForwardIos />}>
                            {i18n.consultCGU}
                        </TextButton>
                    </Grid>
                    <Grid item container alignItems='center' fontSize={14} columnGap='1vw'>
                        <Grid item >{i18n.lastAccept}</Grid>
                        <Grid item className='bold'>{getDate(dateValidCgu)}</Grid>
                    </Grid>
                </LightCard>
                <ModalUpdatePasswordDesktop
                    open={openModal}
                    toggleModal={toggleModal}
                    handleChangeMdp={(e) =>
                        setPassword(e.target.value)
                    }
                    handleChangeNewMdp={(e) =>
                        setNewPassword(e.target.value)
                    }
                    handleChangeConfirmation={(e) =>
                        setNewPasswordConfirmation(e.target.value)
                    }
                    onSavePassword={onSavePassword}
                />
            </Grid>
        </Grid>
    )
}

export default MyAccountDesktop
