import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { push } from 'react-router-redux'
import { Grid } from '@mui/material'
import HomeAction from '../../offline/actions/HomeAction'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import DtoContact from '../referencials/dto/DtoContact'
import ToastrAction from '../components/toasters/ToastrAction'
import { MainButton, TextButton } from '../components/styled/buttons'
import { isValidEmail } from '../../../utils/FormUtils'
import DtoUser from './dto/DtoUser'
import AccountAction from './actions/AccountAction'
import ModalUpdatePassword from './components/ModalUpdatePassword'
import { mainColor } from '../components/styled/theme'
import { getDate } from '../../../utils/DateUtil'
import { ArrowForwardIos, EditOutlined, FolderOutlined } from '@mui/icons-material'
import LoadingCard from '../components/cards/LoadingCard'
import { LightCard } from '../components/styled/grid'
import { Input } from '../components/styled/inputs'

class MyAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            openModal: false,
            isUnvalidEmail: false,
            contact: {},
            name: '',
            phone: '',
            email: '',
            dateValidCgu: '',
            mdp: '',
            newMdp: '',
            mdpConfirmation: '',
            editMode: false,
        }
    }

    componentDidMount() {
        this.getContact()
        this.getDateCgu()
        this.props.setTitle([{
            title: i18n.account,
            href: '/myaccount/preferences',
        }])
    }

    handleChangeValue = (value) => {
        this.setState({ ...value })
    }

    getContact = () => {
        const { accountUser } = this.props
        if (accountUser.contactCode) {
            this.props.fetchContact(accountUser.contactCode).then(() => {
                const { contact } = this.props
                this.setState({
                    dataLoaded: true,
                    contact: contact.id ? contact : new DtoContact({}),
                    name: contact.name || '',
                    phone: contact.phoneTel || '',
                    email: contact.email || '',
                })
            })
        }
        this.setState({ dataLoaded: true })
    }

    createContact = (login) => {
        const { accountUser } = this.props
        this.props.createContact({ name: login }).then((contactCode) => {
            this.props.updateUser({ ...accountUser, contactCode, contactName: login })
            this.props.fetchContact(contactCode).then(() => {
                const { contact } = this.props
                this.setState({
                    dataLoaded: true,
                    contact: contact.id ? contact : new DtoContact({}),
                    name: contact.name || '',
                    phone: contact.phoneTel || '',
                    email: contact.email || '',
                })
            })
        })
    }

    getDateCgu = () => {
        const login = getLogin()
        this.props.getDateValidCGU(login).then(() => {
            const { dateValidCgu } = this.props
            this.setState({ dateValidCgu })
        })
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onSavePassword = () => {
        const { newMdp, mdpConfirmation, mdp } = this.state
        const { applicationSettings } = this.props
        const login = getLogin()
        if (mdp) {
            this.props.login(login, mdp).then(() => {
                if (
                    newMdp !== null &&
                    newMdp.length &&
                    mdpConfirmation !== null &&
                    mdpConfirmation.length
                ) {
                    if (newMdp === mdpConfirmation) {
                        const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                        const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                        if (regex.test(newMdp)) {
                            if (newMdp !== login) {
                                const newMdpEncoding = btoa(unescape(encodeURIComponent(newMdp)))
                                const password = { password: newMdpEncoding }
                                this.props.changePassword(password)
                                this.toggleModal()
                            } else {
                                ToastrAction.error(i18n.passwordNeedToBeDifferent, true)
                            }
                        } else {
                            ToastrAction.error(regexHelp, true)
                        }
                    } else {
                        ToastrAction.error(i18n.passwordAreNotTheSame, true)
                    }
                }
            })
        }
    }

    onSubmit = () => {
        const { contact, name, phone, email } = this.state
        const newContact = { ...contact, name, phoneTel: phone, email }
        if (isValidEmail(email)) {
            this.setState({ isUnvalidEmail: false })
            this.props.updateContact(newContact).then(() => {
                this.getContact()
                this.setState({ editMode: false })
            })
        } else {
            this.setState({ isUnvalidEmail: true })
        }
    }

    onSubmitPassword = () => {
        this.toggleModal()
    }

    render() {
        const { dataLoaded, openModal, dateValidCgu, name, phone, email, isUnvalidEmail, editMode } = this.state
        const { contact, accountUser } = this.props

        if (dataLoaded) {
            return (
                <Grid container justifyContent='center' sx={{ padding: '8px 1.5rem' }}>
                    {(accountUser.isAdmin === '1' || accountUser.metadata === '1') && (
                        <MainButton
                            onClick={() => this.props.push('/admin')}
                            startIcon={<FolderOutlined />}
                            sx={{ marginBottom: '16px' }}
                        >
                            {i18n.selectFolder}
                        </MainButton>
                    )}
                    <LightCard
                        container
                        direction='column'
                        justifyContent='space-around'
                        sx={{ padding: '24px', paddingBottom: editMode ? 0 : '24px', marginBottom: '20px' }}
                    >
                        <Grid item container justifyContent='space-between'>
                            <Grid item fontSize={22} sx={{ marginBottom: '12px' }}>{i18n.ids}</Grid>
                            {!editMode && <EditOutlined onClick={() => this.setState({ editMode: true })} />}
                        </Grid>
                        <Grid
                            container
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                            spacing={2}
                            sx={{ color: mainColor }}
                            fontSize={14}
                        >
                            <Grid item xs={3.5}>
                                {i18n.name}
                            </Grid>
                            <Grid item xs={8.5} className='bold'>
                                {editMode ? (
                                    <Input
                                        id='name'
                                        type='text'
                                        value={name}
                                        onChange={(event) => this.handleChangeValue({ name: event.target.value })}
                                        variant='standard'
                                    />
                                ) : name}
                            </Grid>
                            <Grid item xs={3.5}>
                                {i18n.courriel}
                            </Grid>
                            <Grid item xs={8.5} className='bold'>
                                {editMode ? (
                                    <Input
                                        id='mail'
                                        type='text'
                                        value={email}
                                        onChange={(event) =>
                                            this.handleChangeValue({ email: event.target.value })
                                        }
                                        variant='standard'
                                        error={isUnvalidEmail}
                                        helperText={isUnvalidEmail ? 'Adresse non valide' : ''}
                                    />
                                ) : email}
                            </Grid>
                            <Grid item xs={3.5}>
                                {i18n.phoneTel}
                            </Grid>
                            <Grid item xs={8.5} className='bold'>
                                {editMode ? (
                                    <Input
                                        id='phone'
                                        type='number'
                                        value={phone}
                                        onChange={(event) =>
                                            this.handleChangeValue({ phone: event.target.value })
                                        }
                                        variant='standard'
                                    />
                                ) : phone}
                            </Grid>
                        </Grid>
                        {editMode && (
                            <>
                                <Grid item sx={{ paddingTop: '12px' }}>
                                    <MainButton onClick={this.onSubmit}>
                                        {i18n.saveChanges}
                                    </MainButton>
                                </Grid>
                                <Grid item>
                                    <TextButton sx={{ color: 'black', padding: 0, margin: 0, textDecoration: 'underline' }} onClick={() => {
                                        this.setState({
                                            editMode: false,
                                            name: contact.name || '',
                                            phone: contact.phoneTel || '',
                                            email: contact.email || '',
                                        })
                                    }}>
                                        {i18n.abandon}
                                    </TextButton>
                                </Grid>
                            </>
                        )}
                    </LightCard>
                    <LightCard
                        container
                        direction='column'
                        sx={{ padding: '24px 24px 0 24px' }}
                    >
                        <Grid item fontSize={22} sx={{ marginBottom: '12px' }}>{i18n.password}</Grid>
                        <Grid item container justifyContent='flex-end'>
                            <TextButton noFullWidth={true} onClick={this.onSubmitPassword} endIcon={<EditOutlined />}>
                                {i18n.changePassword}
                            </TextButton>
                        </Grid>
                    </LightCard>
                    <LightCard
                        container
                        direction='column'
                        sx={{ padding: '24px 24px 0 24px', margin: '20px 0' }}
                    >
                        <Grid item fontSize={22} sx={{ marginBottom: '12px' }}>{'CGU'}</Grid>
                        <Grid item container alignItems='center' fontSize={14}>
                            <Grid item xs={6}>{i18n.lastAccept}</Grid>
                            <Grid item xs={6} className='bold'>{getDate(dateValidCgu)}</Grid>
                        </Grid>
                        <Grid item container justifyContent='flex-end'>
                            <TextButton noFullWidth={true} onClick={() => this.props.push('/myaccount/about')} endIcon={<ArrowForwardIos />}>
                                {i18n.consultCGU}
                            </TextButton>
                        </Grid>
                    </LightCard>
                    <Grid item>
                        <MainButton
                            noFullWidth={true}
                            sx={{ width: 'fit-content', padding: '10px 24px' }}
                            onClick={() => this.props.logout()}
                        >
                            {i18n.disconnect}
                        </MainButton>
                    </Grid>
                    <ModalUpdatePassword
                        open={openModal}
                        toggleModal={this.toggleModal}
                        handleChangeMdp={(e) =>
                            this.handleChangeValue({ mdp: e.target.value })
                        }
                        handleChangeNewMdp={(e) =>
                            this.handleChangeValue({ newMdp: e.target.value })
                        }
                        handleChangeConfirmation={(e) =>
                            this.handleChangeValue({ mdpConfirmation: e.target.value })
                        }
                        onSavePassword={this.onSavePassword}
                    />
                </Grid>
            )
        }
        return <LoadingCard />
    }
}

const mapDispatchToProps = {
    fetchUser: AccountAction.fetchUser,
    updateUser: AccountAction.updateUser,
    fetchContact: ContactAction.fetchContact,
    updateContact: ContactAction.updateContact,
    createContact: ContactAction.createContact,
    getDateValidCGU: HomeAction.getDateValidCGU,
    changePassword: AccountAction.changePassword,
    login: HomeAction.login,
    logout: HomeAction.logout,
    setTitle: HomeAction.setTitle,
    push,
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        contact: store.ContactReducer.contact,
        dateValidCgu: store.HomeReducer.dateValidCgu,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

MyAccount.propTypes = {
    fetchUser: PropTypes.func,
    updateUser: PropTypes.func,
    fetchContact: PropTypes.func,
    updateContact: PropTypes.func,
    createContact: PropTypes.func,
    getDateValidCGU: PropTypes.func,
    changePassword: PropTypes.func,
    login: PropTypes.func,
    logout: PropTypes.func,
    accountUser: PropTypes.instanceOf(DtoUser),
    contact: PropTypes.instanceOf(DtoContact),
    dateValidCgu: PropTypes.array,
    applicationSettings: PropTypes.array,
    setTitle: PropTypes.func,
    push: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
